export enum VideoCardType {
  Channel = 'channel',
  Video = 'video',
  RecentVideo = 'recent-video',
  Product = 'product',
  Social = 'social',
  Custom = 'custom',
}

export type VideoCard = {
  actionId: string;
  clientId: string;
  type: VideoCardType;
  from: number;
  to: number;
  position: number;
  orientation: number;
  file: string;
  updatedTimestamp: number;
  text: string;
  thumbnailUrl: string;
  actionListId: string;
  actionItemId: string;
  url: string;
  buttonLabel: string;
};
