import { TFunction } from '@wix/yoshi-flow-editor';

/*
 * @deprecated Use withTranslation HOC or useTranslation hook from @wix/yoshi-flow-editor
 */
export let t: TFunction = (key: string) => key;

export function initTranslation(tFunction: TFunction) {
  t = tFunction;
}
