import { PricingPlansAPI } from '@wix/wix-vod-pricing-plans-api';

export const createGoToPricingPlanPickerHandler = (
  handleGoToPricingPlanPicker,
  httpClient,
) => {
  return async (channelId) => {
    const pricingPlansAPI = new PricingPlansAPI(httpClient);
    const plans = await pricingPlansAPI.getConnectedPlans(channelId);
    const planIds = plans.map((plan) => plan.id);

    handleGoToPricingPlanPicker({
      planIds,
      referralInfo: 'video',
      referralId: channelId,
    });
  };
};
