import { createAction } from 'redux-actions';
import { createAsyncActions } from '../helpers/create-async-actions';

export const CREATE_ORDER = createAsyncActions('PAYMENT.CREATE_ORDER');

export const INIT_CHECKOUT = createAsyncActions('PAYMENT.INIT_CHECKOUT');

export const SELECT_METHOD = createAction('PAYMENT.SELECT_METHOD');

export const statusChange = createAction('PAYMENT.STATUS_CHANGE');

export const setError = createAction('PAYMENT.ERROR');

export const STATUSES = {
  IDLE: 0,
  PENDING: 1,
  DONE: 2,
  FAIL: 3,
};
