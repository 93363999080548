import events from '../constants/events';
import pubsub from './pubsub';

export const DISMISSED = Symbol('dismissed');
export const CANCELLED = Symbol('cancelled');

export function isClose(reason) {
  return reason === DISMISSED;
}

export function isCancel(reason) {
  return reason === CANCELLED;
}

export function closeCatcher(reason) {
  if (isClose(reason)) {
    return;
  }
  return Promise.reject(reason);
}

export function openModal(config) {
  return new Promise(function (resolve, reject) {
    pubsub.publish(events.MODAL.OPEN, {
      ...config,
      resolve,
      reject,
    });
  });
}

export function closeModal() {
  pubsub.publish(events.MODAL.CLOSE);
}
