import { handleActions } from 'redux-actions';
import {
  SET_COMMENTS_OPEN,
  SET_HAS_NEW_COMMENTS,
  SET_OPEN_ON_LOAD,
} from '../actions/comments';

export type CommentsState = {
  isOpen: boolean;
  hasNewComments: boolean;
  openOnLoad: boolean;
};

const defaultState: CommentsState = {
  isOpen: false,
  hasNewComments: false,
  openOnLoad: false,
};

export default handleActions<CommentsState, boolean>(
  {
    [SET_COMMENTS_OPEN]: (state, { payload }) => ({
      ...state,
      isOpen: payload,
      openOnLoad: payload ? false : state.openOnLoad,
    }),
    [SET_HAS_NEW_COMMENTS]: (state, { payload }) => ({
      ...state,
      hasNewComments: payload,
    }),
    [SET_OPEN_ON_LOAD]: (state, { payload }) => ({
      ...state,
      openOnLoad: payload,
    }),
  },
  defaultState,
);
