import {
  getVideoIdToSelect,
  videoToSelectExists,
} from '../selectors/video-id-to-select';
import { selectVideo } from './actions/select-video';
import { getPublicVideo } from './actions/channel/video/get';
import { getPublicChannel } from './actions/channel/get-public';
import { getPaymentInfo } from './actions/channel/payment';
import {
  loadMoreVideoPages,
  prependVideoIds,
} from './lazy-channel-videos/actions';
import { getChannelForWidget } from '@wix/wix-vod-shared/common';
import { clearHydratedData } from './hydrated-data/hydrated-data';
import { getVideoById } from '../selectors/videos';
import { getCurrentChannelId } from '../selectors/channels';
import { ActionCreator } from './redux.types';

const getVideo: ActionCreator = () => async (dispatch, getState) => {
  const state = getState();
  const videoId = getVideoIdToSelect(state);

  if (!videoId) {
    return;
  }
  const videoFetched = Boolean(getVideoById(state, videoId));
  if (videoFetched) {
    return;
  }
  const currentChannelId = getCurrentChannelId(state);
  await dispatch(getPublicVideo(currentChannelId, videoId));
};

const selectVideoIfNeeded: ActionCreator = () => (dispatch, getState) => {
  const state = getState();
  if (videoToSelectExists(state)) {
    // TODO: this might be useless already, video should be loaded 100%
    const videoIdToSelect = getVideoIdToSelect(state);
    dispatch(selectVideo(videoIdToSelect));
  }
};

export const setInitialState: ActionCreator =
  () => async (dispatch, getState) => {
    const state = getState();
    const currentChannelId = getCurrentChannelId(state);

    await Promise.all(
      [
        getPublicChannel(currentChannelId),
        loadMoreVideoPages(),
        getPaymentInfo(currentChannelId),
        getVideo(),
      ].map((action) => dispatch(action)),
    );

    dispatch(clearHydratedData());

    const channelData = getChannelForWidget(getState());

    if (channelData.featuredItem) {
      dispatch(prependVideoIds(channelData.featuredItem));
    }
    dispatch(selectVideoIfNeeded());
  };

export const updateWidgetSingleVideo: ActionCreator =
  () => async (dispatch) => {
    await dispatch(getVideo());
    dispatch(selectVideoIfNeeded());
  };
