import { handleActions } from 'redux-actions';
import { SET_CURRENT_VIDEO_ID } from '../actions/current-video-id';

export type CurrentVideoIdState = string | null;

export default handleActions<CurrentVideoIdState, CurrentVideoIdState>(
  {
    [SET_CURRENT_VIDEO_ID]: (state, { payload }) => payload,
  },
  null,
);
