import { getSiteLocale, getViewMode } from './index';
import type { ExperimentsBag } from '@wix/wix-experiments';
import { getCurrentSiteUser } from './current-site-user';
import { getBiToken } from '../getBiToken';
import { getVideosCount } from '../getVideosCount';
import { getWidgetData, WidgetData } from './getWidgetData';
import { ControllerParams } from '@wix/yoshi-flow-editor';
import { FedopsInteractionsNames } from '../../constants/fedops-interaction-names';
import { getWidgetWarmupData, setWidgetWarmupData } from './warmupData';
import settingsParams from '../../../settingsParams';
import { IWixStyleParams } from '@wix/tpa-settings';
import { getIdsFromFonts, getIdsFromSettings } from '../../utils/ids';

interface GetHydratedSourceOptions {
  experiments: ExperimentsBag;
  isModal: boolean;
  translations: Record<string, string>;
}
export type HydratedSource = Awaited<ReturnType<typeof getHydratedSource>>;

function getIds(
  flowAPI: ControllerParams['flowAPI'],
  styleParams: IWixStyleParams,
  siteOwnerId: string,
) {
  if (flowAPI.settings.get(settingsParams.useIdsFromSettings)) {
    return getIdsFromSettings(
      flowAPI.settings.get(settingsParams.channelId),
      flowAPI.settings.get(settingsParams.videoId),
      siteOwnerId,
    );
  }

  const fonts = styleParams.fonts || {};
  const { channelId, videoId } = fonts;

  return getIdsFromFonts(channelId, videoId, siteOwnerId);
}

export async function getHydratedSource(
  {
    wixCodeApi,
    platformAPIs,
    appParams,
    compId,
    config,
    essentials: { httpClient },
  }: ControllerParams['controllerConfig'],
  flowAPI: ControllerParams['flowAPI'],
  { experiments, isModal, translations }: GetHydratedSourceOptions,
) {
  const viewMode = getViewMode(wixCodeApi);
  const siteOwnerId = platformAPIs.bi?.ownerId || '';
  const { instanceId, instance } = appParams;
  const __SITE_URL__ = wixCodeApi.location.baseUrl;
  const __CURRENT_SITE_USER__ = await getCurrentSiteUser(
    wixCodeApi.user.currentUser,
  );
  const styleParams = config.style.styleParams;
  const siteLocale = getSiteLocale(wixCodeApi);
  const fullSiteUrl = wixCodeApi.location.url; // full url
  const deviceType = wixCodeApi.window.formFactor.toLowerCase();
  const biToken = getBiToken(instance, platformAPIs);
  const metaSiteId = platformAPIs.bi?.metaSiteId;
  const visitorId = platformAPIs.bi?.visitorId;
  const templateMetaSiteId = (platformAPIs.bi as any)?.templateMetaSiteId;
  const { channelId, videoId } = getIds(flowAPI, styleParams, siteOwnerId);

  let widgetData: WidgetData = {} as WidgetData; // we need to understand what to do when we couldn't fetch it, why we return an empty object

  const sendWidgetDataInteraction = () => {
    flowAPI.fedops.interactionStarted(FedopsInteractionsNames.GET_WIDGET_DATA);

    return () => {
      flowAPI.fedops.interactionEnded(FedopsInteractionsNames.GET_WIDGET_DATA);
    };
  };
  if (!isModal) {
    const sendInteractionEnded = sendWidgetDataInteraction();

    const { baseUrl } = wixCodeApi.location;

    const videosCount = getVideosCount({
      styleParams,
    });
    try {
      const warmupData = getWidgetWarmupData(wixCodeApi, compId);
      if (warmupData) {
        widgetData = {
          ...warmupData,
          __TRANSLATIONS__: translations,
        } as WidgetData;
      } else {
        widgetData = await getWidgetData({
          queryParams: {
            channelId,
            videoId,
            instance,
            videosCount,
            metaSiteId,
            locale: siteLocale,
          },
          translations,
          httpClient,
          baseUrl,
          viewMode: wixCodeApi.window.viewMode,
        });
        const { __TRANSLATIONS__, ...widgetWarmupData } = widgetData;

        setWidgetWarmupData(wixCodeApi, compId, widgetWarmupData);
      }

      sendInteractionEnded();
    } catch (e: any) {
      flowAPI.reportError(new Error('Error getting widget data', { cause: e }));
    }
  }

  return {
    channelId,
    videoId,
    siteOwnerId,
    instanceId,
    instance,
    viewMode,
    appSettings: styleParams,
    compId,
    metaSiteId,
    templateMetaSiteId,
    widgetData,
    __SITE_URL__,
    __CURRENT_SITE_USER__,
    __EXPERIMENTS__: experiments,
    siteLocale,
    fullSiteUrl,
    deviceType,
    biToken,
    visitorId,
  };
}
