import { createSelector } from 'reselect';
import {
  hasTrailer as hasVideoTrailer,
  getPreviewType,
  getChannelForWidget,
} from '@wix/wix-vod-shared/common';
import {
  PREVIEW_TYPES,
  TRANSCODING_STATUSES,
} from '@wix/wix-vod-constants/common';
import { isAccessAllowed } from '@wix/wix-vod-shared/widget';

import { RootState } from '../redux/root-state';
import { CurrentVideoIdState } from '../redux/reducers/current-video-id';
import { getCurrentSiteUser } from './current-site-user';
import { getMainVideo } from './get-video';

export const getCurrentVideoId = (state: RootState): CurrentVideoIdState =>
  state.currentVideoId;
export const isSingleVideo = (state: RootState): boolean =>
  !!state.currentVideoId;

const hasAnyTrailer = createSelector(
  [
    (videoItem) =>
      hasVideoTrailer(videoItem) &&
      getPreviewType(videoItem) === PREVIEW_TYPES.TRAILER,
    (videoItem) => getPreviewType(videoItem) === PREVIEW_TYPES.FIRST_20_SEC,
    (videoItem) =>
      videoItem.autogeneratedTrailer?.status === TRANSCODING_STATUSES.READY &&
      getPreviewType(videoItem) === PREVIEW_TYPES.AUTOGENERATED,
  ],
  (hasUploadedTrailer, hasFirst20SecTrailer, hasAutogeneratedTrailer) =>
    hasUploadedTrailer || hasFirst20SecTrailer || hasAutogeneratedTrailer,
);

export const isAccessAllowedOrWithTrailer = createSelector(
  [getMainVideo, getChannelForWidget, getCurrentSiteUser],
  (videoData, channelData, currentSiteUser) => {
    if (!videoData || !channelData) {
      return false;
    }

    return (
      isAccessAllowed(channelData, videoData, currentSiteUser) ||
      hasAnyTrailer(videoData)
    );
  },
);
