import { handleActions } from 'redux-actions';
import { NAMES as SERVER_CHANNEL_VIDEOS_LIST } from '../../actions/channel/videos/list-public';

const increment = (n = 0) => n + 1;
const decrement = (n = 0) => n - 1;

export default handleActions<number, any>(
  {
    [SERVER_CHANNEL_VIDEOS_LIST.START]: increment,
    [SERVER_CHANNEL_VIDEOS_LIST.SUCCESS]: decrement,
    [SERVER_CHANNEL_VIDEOS_LIST.FAIL]: decrement,
  },
  0,
);
