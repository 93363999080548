import _ from 'lodash';
import { Reducer } from 'redux';
import { handleActions } from 'redux-actions';
import { NAMES as CHANNEL_VIDEOS_LIST } from '../actions/channel/videos/list-public';
import { SET_CURRENT_CHANNEL_ID } from '../actions/current-channel-id';

export type FirstChannelVideoIdStateAndPayload = string | null | undefined;

const defaultState: FirstChannelVideoIdStateAndPayload = null;

const resetOnChannelIdChange: Reducer<
  FirstChannelVideoIdStateAndPayload
> = () => defaultState;

const saveVideoId: Reducer<FirstChannelVideoIdStateAndPayload> = (
  state: FirstChannelVideoIdStateAndPayload,
  { payload },
) => {
  if (state) {
    return state;
  }
  return _.get(payload, 'response.data.result[0]', null);
};

export default handleActions<FirstChannelVideoIdStateAndPayload>(
  {
    [CHANNEL_VIDEOS_LIST.SUCCESS]: saveVideoId,
    [SET_CURRENT_CHANNEL_ID]: resetOnChannelIdChange,
  },
  defaultState,
);
