import _ from 'lodash';
import { handleActions } from 'redux-actions';

import {
  GetPublicChannelAction,
  NAMES as CHANNEL_GET,
} from '../../actions/channel/get-public';
import { Channel } from '../../types';

export type InfoState = Channel;

export default handleActions<InfoState, any>(
  {
    [CHANNEL_GET.SUCCESS]: (state, { payload }: GetPublicChannelAction) => {
      const channels = _.values(payload.response?.data.entities.channels);

      return channels[0] || state;
    },
  },
  {} as InfoState,
);
