import { handleActions } from 'redux-actions';
import {
  SELECTED_VIDEO_ACTIONS,
  SelectedVideoIdAction,
} from '../actions/select-video';
import {
  NAMES as VIDEO_PLAYBACK_ACTIONS,
  RequestPlayAction,
} from '../actions/player/change-playback-status';

export type SelectedVideoIdState = string | null;

function resetSelectedVideoID() {
  return null;
}

function setSelectedVideoID(
  state: SelectedVideoIdState,
  { payload }: SelectedVideoIdAction,
) {
  return payload || null;
}

function setVideoIdFromPlayAction(
  state: SelectedVideoIdState,
  { payload }: RequestPlayAction,
) {
  return payload || state;
}

export default handleActions<SelectedVideoIdState, any>(
  {
    [SELECTED_VIDEO_ACTIONS.SET]: setSelectedVideoID,
    [SELECTED_VIDEO_ACTIONS.RESET]: resetSelectedVideoID,
    [VIDEO_PLAYBACK_ACTIONS.REQUEST_PLAY]: setVideoIdFromPlayAction,
  },
  null,
);
