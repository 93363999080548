import { createAction } from 'redux-actions';
import { getOpenPreviewPromptModalConfig } from '../../components/preview-prompt/preview-prompt';
import { getCompId } from '../hydrated-data/hydrated-data';
import { closeCatcher, openModal as _openModal } from '../../utils/modal';

export const OPEN_MODAL = 'CLIENT.OPEN_MODAL';

const openModalAction = createAction(OPEN_MODAL);

export const openModal = (config) => (dispatch, getState) => {
  const compId = getCompId(getState());
  const options = { ...config, compId };

  dispatch(openModalAction(options));
  _openModal(options).catch(closeCatcher);
};

export const openPreviewPrompt = () => (dispatch) => {
  dispatch(openModal(getOpenPreviewPromptModalConfig()));
};
