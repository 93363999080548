import _ from 'lodash';
import {
  createAsyncActions,
  AsyncAction,
} from '../../helpers/create-async-actions';
import { VIDEO_CARD_TYPES } from '@wix/wix-vod-constants/video-cards';
import { ParsedVideoCard } from '@wix/wix-vod-shared/common';
import { getFullSiteUrl, getInstance } from '../../hydrated-data/hydrated-data';
import { getPublicApi } from '../../../api/public';
import { ActionCreator } from '../../redux.types';

type Params = {
  id: string;
};

type Data = (ParsedVideoCard & { url?: string })[];

export type FetchVideoCardsAction = AsyncAction<Data, Params>;

const ACTIONS = createAsyncActions<Data, Params>('SERVER.VIDEO-CARDS.GET');
const { START, SUCCESS, FAIL } = ACTIONS;

const deeplinkCardTypes = [
  VIDEO_CARD_TYPES.CHANNEL,
  VIDEO_CARD_TYPES.VIDEO,
  VIDEO_CARD_TYPES.RECENT_VIDEO,
];

const hasDeeplink = (card: ParsedVideoCard) =>
  _.includes(deeplinkCardTypes, card.type);

const appendDeeplinks = ({
  cardsData,
  fallbackUrl,
  instance,
}: {
  cardsData: ParsedVideoCard[];
  fallbackUrl: string;
  instance: string;
}) => {
  const { publicWidgetsService } = getPublicApi();
  return _.map(cardsData, (card) => {
    if (hasDeeplink(card)) {
      return {
        ...card,
        url: card.actionItemId
          ? publicWidgetsService.buildVideoCardsDeeplinkUrl({
              channelId: card.actionListId || '',
              videoId: card.actionItemId,
              instance,
              fallbackUrl,
              host: '',
            })
          : publicWidgetsService.buildChannelCardsDeeplinkUrl({
              channelId: card.actionListId || '',
              instance,
              fallbackUrl,
              host: '',
            }),
      };
    }

    return card;
  });
};

export const NAMES = ACTIONS.NAMES;

export const fetchVideoCards: ActionCreator = function (
  listId: string,
  itemId: string,
) {
  return function (dispatch, getState) {
    const params: Params = { id: itemId };
    const state = getState();
    const instance = getInstance(state);
    const fullSiteUrl = getFullSiteUrl(state);
    const { videoService } = getPublicApi();

    dispatch(START(params));

    return dispatch(() => videoService.getVideoCardsList(listId, itemId))
      .then((cardsData: ParsedVideoCard[]) =>
        appendDeeplinks({
          cardsData,
          fallbackUrl: fullSiteUrl,
          instance,
        }),
      )
      .then((data: Data) => {
        dispatch(SUCCESS(params, data));
        return data;
      })
      .catch((errorResponse: Error) => {
        dispatch(
          FAIL(params, errorResponse, {
            analytics: {
              type: 'error',
              name: 'shared.video-cards.get',
            },
          }),
        );
      });
  };
};
