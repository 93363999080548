import _ from 'lodash';
import {
  LAYOUTS_MAP,
  getTrailerTypeString,
  isVideoOverQuota,
  getBuyButtonDisplayTypeWhenInOverlay,
  shouldShowSubscribeButton,
  shouldShowChannelCover,
  getPlayButtonType,
  BUY_BUTTON_DISPLAY_TYPES,
  PLAY_BUTTON_TYPES,
} from '@wix/wix-vod-shared/common';
import performance from '../performance';

function getMainItemString(settings, showChannelCover) {
  return shouldShowChannelCover(settings, showChannelCover)
    ? 'channelCover'
    : 'featuredVid';
}

export function getPlayButtonTypeString(channelData, videoItem) {
  return getPlayButtonType(channelData, videoItem) === PLAY_BUTTON_TYPES.PLAY
    ? 'playVid'
    : getTrailerTypeString(videoItem);
}

export function getOpenedEventParams(
  settings,
  channelData,
  videoItem,
  showChannelCover,
  viewMode,
) {
  const layoutId = _.get(settings, 'numbers.channelLayout');
  const playType = getPlayButtonType(channelData, videoItem);

  return {
    loading_time: performance.vars.appFullyRendered,
    viewMode,
    layout: _.get(LAYOUTS_MAP, layoutId),
    buyTypeDisplayed: getBuyButtonDisplayTypeWhenInOverlay({
      settings,
      videoItem,
      channelData,
      showChannelCover,
    }),
    trailerType: getTrailerTypeString(videoItem),
    isTrailerBtn: playType === PLAY_BUTTON_TYPES.TRAILER_OR_PREVIEW,
    isSubscriptionBtn: shouldShowSubscribeButton({
      settings,
      videoItem,
      channelData,
      showChannelCover,
    }),
    mainItem: getMainItemString(settings, showChannelCover),
  };
}

export function getVideoSelectedEventParams({
  appSettings,
  channelData,
  videoItem,
}) {
  const buyType = getBuyButtonDisplayTypeWhenInOverlay({
    settings: appSettings,
    videoItem,
    channelData,
    showChannelCover: false,
  });
  const playType = getPlayButtonType(channelData, videoItem);

  return {
    buyType,
    channelID: _.get(channelData, 'id', 'none'),
    trailerType: getTrailerTypeString(videoItem),
    buyBtn: buyType === BUY_BUTTON_DISPLAY_TYPES.BUY,
    trailerBtn: playType === PLAY_BUTTON_TYPES.TRAILER_OR_PREVIEW,
    playBtn: playType === PLAY_BUTTON_TYPES.PLAY,
    videoID: _.get(videoItem, 'id', 'none'),
    overQuoted: isVideoOverQuota(videoItem),
  };
}
