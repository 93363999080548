// https://github.com/moment/moment/blob/497f918515ae6ab900f008f19523b1e4ae5e2450/src/lib/duration/iso-string.js

function absFloor(number) {
  if (number < 0) {
    // -0 -> 0
    return Math.ceil(number) || 0;
  } else {
    return Math.floor(number);
  }
}

export function durationToISOString(secondsInput) {
  // for ISO strings we do not use the normal bubbling rules:
  //  * milliseconds bubble up until they become hours
  //  * days do not bubble at all
  //  * months bubble up until they become years
  // This is because there is no context-free conversion between hours and days
  // (think of clock changes)
  // and also not between days and months (28-31 days per month)
  let seconds = Math.abs(secondsInput);
  const days = 0;
  let months = 0;
  let minutes;

  // 3600 seconds -> 60 minutes -> 1 hour
  minutes = absFloor(seconds / 60);
  const hours = absFloor(minutes / 60);
  seconds %= 60;
  minutes %= 60;

  // 12 months -> 1 year
  const years = absFloor(months / 12);
  months %= 12;

  // inspired by https://github.com/dordille/moment-isoduration/blob/master/moment.isoduration.js
  const Y = years;
  const M = months;
  const D = days;
  const h = hours;
  const m = minutes;
  const s = seconds;
  const total = secondsInput;

  if (!total) {
    // this is the same as C#'s (Noda) and python (isodate)...
    // but not other JS (goog.date)
    return 'P0D';
  }

  return (
    (total < 0 ? '-' : '') +
    'P' +
    (Y ? Y + 'Y' : '') +
    (M ? M + 'M' : '') +
    (D ? D + 'D' : '') +
    (h || m || s ? 'T' : '') +
    (h ? h + 'H' : '') +
    (m ? m + 'M' : '') +
    (s ? s + 'S' : '')
  );
}
