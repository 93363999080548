import { IPubSub } from '@wix/yoshi-flow-editor';
import { getInitialPubSubEvents } from './index';
import EVENTS from '../../constants/events';
import { REFRESH_EVENT } from '../../utils/reload';
import { BI_EVENT } from '../../bi/constants';
import WixEvents from '../../constants/wix-sdk';
import { debounce } from 'lodash';

export function subscribeToEvents(
  setProps: (props: { [key: string]: any }) => void,
  pubSub: IPubSub,
) {
  const pubSubEvents = getInitialPubSubEvents();
  const debouncedSetProps = debounce(setProps, 100);

  const handlePubSubEventSeries = (
    eventName: string,
    isPersistent: boolean,
  ) => {
    pubSub.subscribe(
      eventName,
      (data) => {
        pubSubEvents[eventName] = [...pubSubEvents[eventName], data].slice(-10);
        debouncedSetProps({ pubSubEvents: { ...pubSubEvents } });
      },
      isPersistent,
    );
  };

  const handlePubSubEvent = (
    eventName: string,
    isPersistent: boolean = false,
  ) => {
    pubSub.subscribe(
      eventName,
      (data) => {
        pubSubEvents[eventName] = data;
        debouncedSetProps({ pubSubEvents: { ...pubSubEvents } });
      },
      isPersistent,
    );
  };

  const handleEvent = (eventName: string) => {
    const Wix = (global as any).Wix;
    if (Wix) {
      Wix.addEventListener(eventName, (data: any) => {
        pubSubEvents[eventName] = data;
        debouncedSetProps({ pubSubEvents: { ...pubSubEvents } });
      });
    }
  };

  const subscribe = () => {
    handlePubSubEventSeries(REFRESH_EVENT, true);
    handlePubSubEvent(EVENTS.SESSION.LOGIN_USER);
    handlePubSubEvent(EVENTS.SESSION.LOGOUT_USER);
    handlePubSubEvent(EVENTS.PAYMENT.PAYMENT_EVENT);
    handlePubSubEvent(BI_EVENT);

    handleEvent(WixEvents.PAGE_NAVIGATION);
    handleEvent(WixEvents.SETTINGS_UPDATED);
    handleEvent(WixEvents.SITE_PUBLISHED);
    handleEvent(WixEvents.EDIT_MODE_CHANGE);
    handleEvent(WixEvents.STYLE_PARAMS_CHANGE);
  };

  subscribe();
}
