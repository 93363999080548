import { Action } from 'redux-actions';
import {
  AsyncActionPayload,
  createAsyncActions,
} from '../../helpers/create-async-actions';
import { normalizeChannel, NormalizedChannels } from '../../helpers/normalized';
import { getHydratedData } from '../../hydrated-data/hydrated-data';
import { ActionCreator } from '../../redux.types';
import { getPublicApi } from '../../../api/public';
import { Channel } from '../../types';

type PublicChannelParams = { id: string };
type PublicChannelResponse = {
  data: NormalizedChannels;
};
export type GetPublicChannelAction = Action<
  AsyncActionPayload<PublicChannelResponse, PublicChannelParams>
>;
const ACTIONS = createAsyncActions<PublicChannelResponse, PublicChannelParams>(
  'SERVER.CHANNEL.GET',
);
export const NAMES = ACTIONS.NAMES;
const { START, SUCCESS, FAIL } = ACTIONS;

export const getPublicChannel: ActionCreator =
  (id: string) => (dispatch, getState) => {
    const params = { id };
    const state = getState();
    const { channelService } = getPublicApi();
    const { channel: hydratedChannel, templateMetaSiteId } =
      getHydratedData(state);
    dispatch(START(params));
    const channelPromise: Promise<Channel> = hydratedChannel
      ? Promise.resolve(hydratedChannel)
      : channelService.getInfo(id, templateMetaSiteId);
    return channelPromise.then(
      function (channelData) {
        const result: PublicChannelResponse = {
          data: normalizeChannel(channelData),
        };

        dispatch(SUCCESS(params, result));
        return result;
      },

      function (response) {
        dispatch(
          FAIL(params, response, {
            analytics: { type: 'error', name: 'shared.channel.video.get' },
          }),
        );
      },
    );
  };
