import { IHttpClient } from '@wix/http-client';
import { PrivateWidgetsService } from '@wix/wix-vod-api/privateWidgets';
import { PrivateSettingsServiceV2 as PrivateSettingsService } from '@wix/wix-vod-api/privateSettings';

export let privateApi: {
  widgets: PrivateWidgetsService;
  settings: PrivateSettingsService;
};

export function initPrivateServices(httpClient: IHttpClient, baseUrl?: string) {
  privateApi = {
    widgets: new PrivateWidgetsService(httpClient),
    settings: new PrivateSettingsService(httpClient, baseUrl),
  };
}
