import { noop } from 'lodash';
import { getPricingPlansPublicAPI } from '@wix/pricing-plans-tpa-api';
import { createWorkerHandler } from './createHandler';

export function createWorkerHandlerForOOI(
  controllerConfig,
  { debug },
  flowAPI,
) {
  const { wixCodeApi } = controllerConfig;

  return createWorkerHandler(
    controllerConfig,
    {
      handleFitIntoView: noop,
      handleLogin: async ({ mode, language }, onLogin, onError) => {
        try {
          const user = await wixCodeApi.user.promptLogin({ mode, language });

          onLogin({
            id: user.id,
            email: await wixCodeApi.user.currentUser.getEmail(),
          });
        } catch (error) {
          onError(error);
        }
      },
      handleLogout: wixCodeApi.user.logout,
      handleGoToPricingPlanPicker: async ({
        planIds,
        referralInfo,
        referralId,
      }) => {
        const api = await getPricingPlansPublicAPI(wixCodeApi);
        api.navigateToPricingPage({
          planIds,
          biOptions: {
            referralId,
            referralInfo,
          },
          checkout: {
            successStatus: {
              navigation: {
                type: 'url',
                url: wixCodeApi.location.url,
              },
            },
          },
        });
      },
    },
    debug,
    flowAPI,
  );
}
