import { createAction } from 'redux-actions';
import { Video } from '../types/video';

export const NAMES = {
  UPDATE_VIDEO_LIVE_STATUS: 'CLIENT.VIDEOS.UPDATE_LIVE_STATUS',
};

export type UpdateLiveStatusPayload = Video;

export const updateLiveStatus = createAction<UpdateLiveStatusPayload>(
  NAMES.UPDATE_VIDEO_LIVE_STATUS,
);
