import { combineReducers, Reducer } from 'redux';

import { connectRouter } from 'connected-react-router';

import slideMenuOpened from './reducers/slide-menu-opened';
import modules from './reducers/modules';

import videos from '../../redux/reducers/videos';
import channels from '../../redux/reducers/channels';
import channelVideos from '../../redux/reducers/channel-videos';

import configuration from '../../redux/reducers/configuration';
import appSettings from '../../redux/reducers/app-settings';
import currentChannelId from '../../redux/reducers/current-channel-id';
import currentVideoId from '../../redux/reducers/current-video-id';
import search from '../../redux/reducers/search';
import channelInfo from '../../redux/reducers/channel-info';
import firstChannelVideoId from '../../redux/reducers/first-channel-video-id';
import shareOverlay from '../../redux/reducers/share-overlay';
import videoUrlsCache from '../../redux/reducers/video-urls-cache';
import selectedVideoId from '../../redux/reducers/selected-video-id';
import player from '../../redux/reducers/player';
import isVideosTouched from '../../redux/reducers/is-videos-touched';
import playback from '../../redux/reducers/playback';
import videoCards from '../../redux/reducers/video-cards';
import payment from '../../redux/reducers/payment';
import lazyChannelVideos from '../../redux/lazy-channel-videos/reducer';
import isLightbox from '../../redux/reducers/is-lightbox';
import fullScreenModal from '../../redux/reducers/full-screen-modal';
import comments from '../../redux/reducers/comments';
import { reducer as hydratedData } from '../../redux/hydrated-data/hydrated-data';
import controllerState from '../../redux/reducers/controller-state';
import { History } from 'history';
import { RootState } from '../../redux/root-state';

export type MobileRootState = RootState & {
  slideMenuOpened: boolean;
  modules: { playbackModuleLoaded: boolean };
};
export default ({ history }: { history: History }): Reducer<MobileRootState> =>
  combineReducers<MobileRootState>({
    router: connectRouter(history),

    slideMenuOpened,
    modules,

    search,
    entities: combineReducers({
      videos,
      channels,
    }),
    channelVideos,
    currentChannelId,
    currentVideoId,
    channelInfo,
    firstChannelVideoId,

    lazyChannelVideos,
    isLightbox,

    videoUrlsCache,
    selectedVideoId,
    player,
    isVideosTouched,
    payment,

    configuration,
    appSettings,
    videoCards,
    shareOverlay,

    fullScreenModal,
    comments,
    hydratedData,
    playback,
    controllerState,
  });
