import { createAsyncActions } from '../../helpers/create-async-actions';
import { getInstance } from '../../hydrated-data/hydrated-data';
import { createAsyncProxy } from '../../../worker/lib';
import { getBaseUrl } from '../../../selectors/api';
import { getPublicApi } from '../../../api/public';

const ACTIONS = createAsyncActions('CLIENT.VIDEO_URLS.GET');
export const NAMES = ACTIONS.NAMES;
const { START, SUCCESS, FAIL } = ACTIONS;

export const fetchVideoUrls = createAsyncProxy(
  'fetchVideoUrls',
  (videoItem, force = false) =>
    (dispatch, getState) => {
      const state = getState();
      const { videoUrlsCache } = state;
      const instance = getInstance(state);
      const baseUrl = getBaseUrl(state);

      if (!force && (!videoItem || videoUrlsCache[videoItem.id])) {
        return;
      }

      const params = { videoItem };

      dispatch(START(params));

      /*
       * When videoItem is a bought video response from member/info and original video is too far in list
       * and wasn't loaded yet
       * */
      if (videoItem.dgsInfo && !videoItem.itemId && !videoItem.listId) {
        return Promise.resolve();
      }

      // IN V2 there is listId, but in V3 - isn't
      const channelId = videoItem.listId || state.channelInfo.info.id;

      return getPublicApi()
        .videoService.getVideoUrls(
          videoItem.itemId,
          channelId,
          instance,
          baseUrl,
        )
        .then(
          function (response) {
            dispatch(SUCCESS(params, response));
            return response;
          },

          function (response) {
            dispatch(FAIL(params, response));
          },
        );
    },
);
