import _ from 'lodash';
import { Action } from 'redux-actions';
import {
  AsyncActionPayload,
  createAsyncActions,
} from '../../../helpers/create-async-actions';
import { isVODChannel } from '../../../../utils/channel-helpers';
import {
  normalizeArrayOfVideos,
  NormalizedArrayOfVideos,
} from '../../../helpers/normalized';
import { getHydratedData } from '../../../hydrated-data/hydrated-data';
import { getPublicApi } from '../../../../api/public';
import { ActionCreator } from '../../../redux.types';
import { MEDIA_TYPES, URL_TYPES } from '@wix/wix-vod-constants/common';
import { SortOptions } from '@wix/wix-vod-constants/api-types';
import { getChannelById } from '../../../../selectors/channels';

type Options = {
  paging: {
    size: number;
    cursor: string;
  };
  query: string;
  tag: string;
  category: string;
};

type ListPublicParams = {
  id: string;
  options: Options;
};

export type ListPublicResponse = {
  paging: {
    size?: number;
    cursor?: string | null;
  };
  sort: SortOptions;
  data: NormalizedArrayOfVideos;
};

export type ListPublicChannelVideosAction = Action<
  AsyncActionPayload<ListPublicResponse, ListPublicParams>
>;

const ACTIONS = createAsyncActions<ListPublicResponse, ListPublicParams>(
  'SERVER.CHANNEL.VIDEOS.LIST',
);
export const NAMES = ACTIONS.NAMES;
const { START, SUCCESS, FAIL } = ACTIONS;

export const defaultParams = {
  sort: {
    order: 'custom',
    direction: 'asc',
  },
  paging: {
    size: 1000,
  },
};

export const listPublicChannelVideos: ActionCreator = (
  channelId: string,
  opts?: Options,
) => {
  const options: Options = _.merge({}, defaultParams, opts);

  return async (dispatch, getState) => {
    const state = getState();

    const params = { id: channelId, options };
    const {
      channel: hydratedChannel,
      channelVideos: hydratedChannelVideos,
      templateMetaSiteId,
    } = getHydratedData(state);

    dispatch(START(params));

    let response;
    const publicApi = getPublicApi();

    const channel = hydratedChannel ?? getChannelById(state, channelId);

    try {
      if (channel && hydratedChannelVideos) {
        response = hydratedChannelVideos;
      } else if (channel && !isVODChannel(channel)) {
        response =
          await publicApi.youtubeVideosService.listYoutubeChannelVideos(
            channel,
            options,
          );
      } else {
        response = await publicApi.channelVideos.listChannelVideos({
          ...options,
          channelId,
          templateMetaSiteId,
          mediaType: MEDIA_TYPES.SECURE_VIDEO,
        });

        if (!response.data.length) {
          const channelResponse = await publicApi.channelService.getInfo(
            channelId,
          );

          if (channelResponse.externalId) {
            response =
              await publicApi.youtubeVideosService.listYoutubeChannelVideos(
                {
                  externalId: channelResponse.externalId,
                  urlType: channelResponse.urlType as
                    | URL_TYPES.PLAYLIST
                    | URL_TYPES.CHANNEL,
                },
                options,
              );
          }
        }
      }

      const data: ListPublicResponse = {
        paging: response.paging,
        sort: response.sort,
        data: normalizeArrayOfVideos(response.data),
      };

      dispatch(SUCCESS(params, data));
      return data;
    } catch (error) {
      dispatch(
        FAIL(params, error, {
          analytics: { type: 'error', name: 'shared.channel.videos.list' },
        }),
      );
    }
  };
};
