import { createAction, Action } from 'redux-actions';
import { ActionCreator } from '../redux.types';

export const SELECTED_VIDEO_ACTIONS = {
  SET: 'CLIENT.SELECTED_VIDEO.SET',
  RESET: 'CLIENT.SELECTED_VIDEO.RESET',
};

const selectVideoAction = createAction(SELECTED_VIDEO_ACTIONS.SET);

export type SelectedVideoIdAction = Action<string>;

export const selectVideo: ActionCreator =
  (videoId: string) => (dispatch, getState) => {
    const selectedVideoId = getState().selectedVideoId;

    if (selectedVideoId !== videoId) {
      dispatch(selectVideoAction(videoId));
    }
  };

export type ResetSelectedVideoAction = Action<void>;

export const resetSelectedVideo = createAction<void>(
  SELECTED_VIDEO_ACTIONS.RESET,
);
