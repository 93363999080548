import { handleActions } from 'redux-actions';

import { NAMES as VIDEO_PLAYBACK_ACTIONS } from '../../actions/player/change-playback-status';
import { SELECTED_VIDEO_ACTIONS } from '../../actions/select-video';

import { VIDEO_PLAYBACK_STATUSES } from '@wix/wix-vod-constants/common';

const defaultState = VIDEO_PLAYBACK_STATUSES.INITIAL;

const setPlayRequestedStatus = () => VIDEO_PLAYBACK_STATUSES.PLAY_REQUESTED;
const setPlayStatus = () => VIDEO_PLAYBACK_STATUSES.PLAYING;
const setPlayAbortedStatus = () => VIDEO_PLAYBACK_STATUSES.PLAY_ABORTED;
const setPauseStatus = (state) => {
  // fix toggling pause for player
  // if video is switched, we are reset playback status, but old player did this too
  // TODO: remove after using videoPlaybackStatus at player instead of props.paused
  if (state === VIDEO_PLAYBACK_STATUSES.INITIAL) {
    return state;
  }
  return VIDEO_PLAYBACK_STATUSES.PAUSED;
};
const setPauseRequestedStatus = () => VIDEO_PLAYBACK_STATUSES.PAUSE_REQUESTED;
const setEndedStatus = () => VIDEO_PLAYBACK_STATUSES.ENDED;
const resetStatus = () => VIDEO_PLAYBACK_STATUSES.INITIAL;

const setStatus = (state, { payload: status }) => status;

export default handleActions(
  {
    [VIDEO_PLAYBACK_ACTIONS.PLAY]: setPlayStatus,
    [VIDEO_PLAYBACK_ACTIONS.REQUEST_PLAY]: setPlayRequestedStatus,
    [VIDEO_PLAYBACK_ACTIONS.ABORT_PLAY]: setPlayAbortedStatus,
    [VIDEO_PLAYBACK_ACTIONS.PAUSE]: setPauseStatus,
    [VIDEO_PLAYBACK_ACTIONS.REQUEST_PAUSE]: setPauseRequestedStatus,
    [VIDEO_PLAYBACK_ACTIONS.RESET]: resetStatus,
    [VIDEO_PLAYBACK_ACTIONS.END]: setEndedStatus,
    [VIDEO_PLAYBACK_ACTIONS.SET]: setStatus,
    [SELECTED_VIDEO_ACTIONS.SET]: resetStatus,
    [SELECTED_VIDEO_ACTIONS.RESET]: resetStatus,
  },
  defaultState,
);
