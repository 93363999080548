import _ from 'lodash';
import { createSelector, Selector } from 'reselect';
import { denormalize } from 'normalizr';

import { video as videoSchema } from '../redux/helpers/schemas';
import {
  isFree as isVideoFree,
  isSaleEnabled,
} from '@wix/wix-vod-shared/common';
import { PreviewType, Video } from '../redux/types';
import { RootState } from '../redux/root-state';
import { VideosState } from '../redux/reducers/videos';

function withDealInfoFromSettings(state: RootState, video: Video) {
  if (!video) {
    return video;
  }

  video.memberOnly = isVideoFree(video) ? video.memberOnly : false;

  video.allowDownload =
    isVideoFree(video) || isSaleEnabled(video) ? video.allowDownload : false;

  if (_.isEmpty(video.previewType)) {
    video.previewType = isVideoFree(video)
      ? PreviewType.NONE
      : PreviewType.FIRST_20_SEC;
  }
  return video;
}

export const getEntities = (state: RootState) => state.entities;

export const getVideoEntities: Selector<RootState, VideosState> =
  createSelector(getEntities, _.property('videos'));
const getVideosCount = createSelector(getVideoEntities, _.size);

export function getVideoById(state: RootState, videoId: string | null) {
  return videoId !== null ? getVideoEntities(state)[videoId] : undefined;
}
export const hasMoreThanOneVideo = createSelector(
  getVideosCount,
  (count) => count > 1,
);

/**
 * @param state
 * @param videoId {String}
 * @returns {Object} video
 */
export const getVideoWithChannelDataById = createSelector(
  [(state) => state, getVideoById],
  (state, videoData) =>
    // TODO remove denormalize. Used to add channel data to usedInLists
    withDealInfoFromSettings(
      state,
      denormalize(videoData, state.entities, videoSchema),
    ),
);

/**
 * @param state
 * @returns {Object} {id: video}
 */
export const getVideosGroupedByIds: Selector<RootState, VideosState> =
  createSelector([(state) => state, getVideoEntities], (state, videosByIds) =>
    _.mapValues(videosByIds, _.partial(withDealInfoFromSettings, state)),
  );

/**
 * @param state
 * @param ids {String[]}
 * @returns {Object[]} videos list
 */
export const getVideosByIds = createSelector(
  [getVideosGroupedByIds, (state, ids) => ids],
  _.pick,
);

/**
 * @param state
 * @param ids {String[]}
 * @param currentVideoId {String}
 * @returns {Object[]} videos list
 */
export const getVideosByIdsWithoutCurrent = createSelector(
  [getVideosByIds, (state, ids, currentVideoId) => currentVideoId],
  _.omit,
);
