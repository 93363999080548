import { createAction } from 'redux-actions';
import { openFullScreenOverlay } from '../../utils/open-overlay-base';
import { ActionCreator } from '../redux.types';

export const NAMES = {
  OPEN_FULL_SCREEN_MODAL_VIDEO: 'CLIENT.FULL_SCREEN_MODAL.VIDEO.OPEN',
  CLOSE_FULL_SCREEN_MODAL_VIDEO: 'CLIENT.FULL_SCREEN_MODAL.VIDEO.CLOSE',
};

const openFullScreenModalVideo = createAction(
  NAMES.OPEN_FULL_SCREEN_MODAL_VIDEO,
);

export const closeFullScreenVideoOverlay = createAction(
  NAMES.CLOSE_FULL_SCREEN_MODAL_VIDEO,
);

export const openFullScreenVideoOverlay: ActionCreator =
  (channelId: string, videoId: string, autoplay = false, onClose: Function) =>
  (dispatch) => {
    const route = channelId
      ? `#/channel/${channelId}/video/${videoId}?autoplay=${autoplay}`
      : `#/video/${videoId}?autoplay=${autoplay}`;
    dispatch(openFullScreenOverlay(route, onClose));
    dispatch(openFullScreenModalVideo());
  };
