import { createStore as createStoreWidget } from '../../widget.store';
import { createStore as createStoreMobile } from '../../mobile/mobile.store';
import { setInitialState as setInitialStateDesktop } from '../../redux/initial-data-desktop';
import { setInitialState as setInitialStateMobile } from '../../mobile/initial-data-mobile';

export const getStoreBuilderForOOI = (isMobile: boolean) => {
  if (isMobile) {
    return {
      createStore: createStoreMobile,
      setInitialState: setInitialStateMobile,
    };
  }
  return {
    createStore: createStoreWidget,
    setInitialState: setInitialStateDesktop,
  };
};
