import _ from 'lodash';
import {
  ActionFunctionAny,
  createAction,
  Action,
  ActionFunction2,
  ActionMeta,
} from 'redux-actions';

export type AsyncActionPayload<TResponse, TParams> = {
  params?: TParams;
  response?: TResponse;
};

export type AsyncAction<TResponse, TParams> = Action<
  AsyncActionPayload<TResponse, TParams>
>;

type Actions<TResponse, TParams, TMeta> = {
  START: ActionFunctionAny<Action<any>>;
  SUCCESS: ActionFunction2<
    TParams,
    TResponse,
    ActionMeta<AsyncActionPayload<TResponse, TParams>, TMeta>
  >;
  FAIL: ActionFunctionAny<Action<any>>;
  NAMES: {
    START: string;
    SUCCESS: string;
    FAIL: string;
  };
};

function toPayload<TResponse, TParams>(params: TParams, response?: TResponse) {
  const payload: AsyncActionPayload<TResponse, TParams> = {
    params,
  };

  if (response) {
    payload.response = response;
  }
  return payload;
}

function toMeta<TResponse, TParams, TMeta>(
  params: TParams,
  response: TResponse,
  meta: any = null,
): TMeta {
  if (meta && _.isArray(meta.analytics)) {
    return meta;
  }
  if (meta && meta.analytics && !meta.analytics.payload) {
    const { type, ...payload } = meta.analytics;
    meta.analytics = { type, payload };
  }
  return meta;
}

export function createAsyncActions<TResponse, TParams, TMeta = undefined>(
  namespace: string,
): Actions<TResponse, TParams, TMeta> {
  const START = `${namespace}.START`;
  const SUCCESS = `${namespace}.SUCCESS`;
  const FAIL = `${namespace}.FAIL`;

  return {
    NAMES: {
      START,
      SUCCESS,
      FAIL,
    },
    START: createAction(START, toPayload, toMeta),
    SUCCESS: createAction<
      AsyncActionPayload<TResponse, TParams>,
      TMeta,
      TParams,
      TResponse
    >(SUCCESS, toPayload, toMeta),
    FAIL: createAction(FAIL, toPayload, toMeta),
  };
}
