import { removeDashes } from '@wix/wix-vod-shared/common';
import { DEMO_CHANNEL_ID } from '@wix/wix-vod-constants/env';
import type { ISettingsFont } from '@wix/tpa-settings';

function getIds(channelId: string, videoId: string, siteOwnerId: string) {
  if (!channelId && !videoId) {
    return {
      channelId: DEMO_CHANNEL_ID,
      videoId: '',
    };
  }

  return {
    // if videoId is present the channelId should be UID
    channelId: videoId ? siteOwnerId : removeDashes(channelId),
    videoId: removeDashes(videoId),
  };
}

export function getIdsFromSettings(
  channelId: string,
  videoId: string,
  siteOwnerId: string,
) {
  return getIds(channelId, videoId, siteOwnerId);
}

export function getIdsFromFonts(
  channelIdFont: ISettingsFont,
  videoIdFont: ISettingsFont,
  siteOwnerId: string,
) {
  let channelId = '';
  let videoId = '';

  if (typeof channelIdFont === 'string') {
    channelId = channelIdFont;
  }

  if (typeof videoIdFont === 'string') {
    videoId = videoIdFont;
  }

  return getIds(channelId, videoId, siteOwnerId);
}
