import _ from 'lodash';
import { createSelector } from 'reselect';
import { BASIC_LAYOUT, VERTICAL_LAYOUT } from '@wix/wix-vod-constants/mobile';
import {
  getAllSettings,
  getNumberOfRows,
  isStripLayout,
  isClassicLayout,
  getVideosInRowCount,
  isCompactLayout,
  isGridLayout,
  isVerticalLayoutMobile,
  isSliderLayout,
} from '../../selectors/app-settings';
import {
  COMPACT_NUMBER_OF_ROWS,
  COMPACT_VIDEOS_IN_ROW,
} from '@wix/wix-vod-constants/app-settings';
import { VIDEO_LIST_ITEM_WIDTH } from '../../constants/lightbox';
import { getMaxVideosCountInRow as getGridVideosPerRow } from '../../layouts/grid/selectors';
import { getVideosGroupedByIds } from '../../selectors/videos';
import { isMobile } from '../../selectors/form-factor';

const DEFAULT_VIDEOS_COUNT = 8;

export const getVideosPerPageCount = createSelector(
  getAllSettings,
  getNumberOfRows,
  getVideosInRowCount,
  getGridVideosPerRow,
  isStripLayout,
  isClassicLayout,
  isCompactLayout,
  isGridLayout,
  isVerticalLayoutMobile,
  (state) => state.isLightbox,
  isMobile,
  isSliderLayout,
  (
    appSettings,
    numberOfRows,
    videosInRow,
    gridVideosInRow,
    isStrip,
    isClassic,
    isCompact,
    isGrid,
    isGridMobile,
    isLightbox,
    isMobile,
    isSlider,
  ) => {
    if (!appSettings) {
      return 0;
    }

    if (isMobile) {
      return isGridMobile ? VERTICAL_LAYOUT : BASIC_LAYOUT;
    }

    if (isLightbox) {
      return Math.ceil(window.innerWidth / VIDEO_LIST_ITEM_WIDTH);
    }

    if (isGrid) {
      return gridVideosInRow * numberOfRows;
    }

    if (isCompact) {
      return COMPACT_NUMBER_OF_ROWS * COMPACT_VIDEOS_IN_ROW;
    }

    if (isStrip) {
      return videosInRow * numberOfRows;
    }

    if (isClassic) {
      return videosInRow * numberOfRows;
    }

    if (isSlider) {
      return DEFAULT_VIDEOS_COUNT;
    }

    return DEFAULT_VIDEOS_COUNT;
  },
);

export const getListVideoIds = (state) => state.lazyChannelVideos.ids;
export const getPrependedVideoIds = (state) =>
  state.lazyChannelVideos.prependedIds;

export const getVideoIds = createSelector(
  getPrependedVideoIds,
  getListVideoIds,
  (prependedIds, ids) => [...prependedIds, ..._.without(ids, ...prependedIds)],
);

export const getChannelVideos = createSelector(
  getVideoIds,
  getVideosGroupedByIds,
  (channelVideoIds, allVideos) => _.map(channelVideoIds, (id) => allVideos[id]),
);

export const getCursor = (state) => state.lazyChannelVideos.cursor;
export const getCurrentPageIndex = (state) =>
  state.lazyChannelVideos.currentPageIndex;
export const getCurrentVideoIndex = (state) =>
  state.lazyChannelVideos.currentVideoIndex;
export const getIsFetching = (state) => state.lazyChannelVideos.isFetching;

export const getVideoIdsByPageNumber = createSelector(
  getVideoIds,
  getVideosPerPageCount,
  (ids, videosPerPage) => _.chunk(ids, videosPerPage),
);

export const hasPrevVideo = createSelector(
  getVideoIds,
  getCurrentVideoIndex,
  (ids, index) => Boolean(ids[index - 1]) && index !== -1,
);

export const hasNextVideo = createSelector(
  getVideoIds,
  getCurrentVideoIndex,
  (ids, index) => Boolean(ids[index + 1]) && index !== -1,
);

export const getNextVideoId = createSelector(
  getVideoIds,
  getCurrentVideoIndex,
  (ids, index) => ids[index + 1] || null,
);
