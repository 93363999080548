import _ from 'lodash';
import { createSelector } from 'reselect';
import { createAction, handleActions } from 'redux-actions';
import { parseHydratedData } from './parsers';
import { InitialData } from '../../worker/helpers/create-store';
import { Dispatch } from 'redux';
import { RootState } from '../root-state';

/* actions */

export const SET_HYDRATED_DATA = 'CLIENT.HYDRATED_DATA.SET';
export const CLEAR_HYDRATED_DATA = 'CLIENT.HYDRATED_DATA.CLEAR';

export const setHydratedData = createAction(SET_HYDRATED_DATA);
export const clearHydratedData = createAction(CLEAR_HYDRATED_DATA);

export const setHydratedDataFromSource =
  (source: InitialData) => (dispatch: Dispatch) => {
    const parsedData = parseHydratedData(source);
    dispatch(setHydratedData(parsedData));
  };

interface HydratedDataState {
  isFetching: boolean;
  data:
    | {
        channelVideos: unknown;
        singleVideo: unknown;
        widgetData: unknown;
      }
    | {};
}

const defaultState: HydratedDataState = {
  isFetching: false,
  data: {},
};

const merge = <T extends {}>(
  state: HydratedDataState,
  { payload }: { payload: T },
) => ({
  ...state,
  data: _.merge({}, state.data, payload),
});

const clear = (state: HydratedDataState) => ({
  ...state,
  data: _.omit(state.data, ['channelVideos', 'singleVideo', 'widgetData']),
});

/* reducer */
export const reducer = handleActions(
  {
    [SET_HYDRATED_DATA]: merge,
    [CLEAR_HYDRATED_DATA]: clear,
  },
  defaultState,
);

/* selectors */

export const getHydratedData = (state: RootState) => state.hydratedData.data;

const getKey = (key: string) => (state: RootState) =>
  _.get(state.hydratedData.data, key);

export const getCurrentPageId = getKey('currentPageId');
export const getInstance = getKey('instance');
export const getSiteOwnerId = getKey('siteOwnerId');
export const getUid = getKey('currentSiteUser.id');

export const getBiToken = getKey('biToken');
export const getLocale = getKey('locale');
export const getMetaSiteId = getKey('metaSiteId');
export const getTemplateMetaSiteId = getKey('templateMetaSiteId');
export const getInstanceId = getKey('instanceId');
export const isUnsavedTemplateSite = createSelector(
  getTemplateMetaSiteId,
  (templateMetaSiteId) => Boolean(templateMetaSiteId),
);

/* protocol + domain + path + query: https://testsite.com/basepath?query=true */
export const getFullSiteUrl = getKey('fullSiteUrl');

/* protocol + domain + path: https://testsite.com/basepath */
export const getSiteUrl = getKey('siteUrl');
export const getCompId = getKey('compId');
export const getVisitorId = getKey('visitorId');
