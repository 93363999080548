import { IHttpClient } from '@wix/http-client';
import {
  createPublicPurchaseService,
  PublicVideoApiService,
  PublicChannelVideosService,
  PublicWidgetsService,
  YoutubeVideosService,
  PublicPurchaseService,
  PublicChannelService,
} from '@wix/wix-vod-api/public';

interface PublicAPI {
  purchase: PublicPurchaseService;
  videoService: PublicVideoApiService;
  channelService: PublicChannelService;
  channelVideos: PublicChannelVideosService;
  youtubeVideosService: YoutubeVideosService;
  publicWidgetsService: PublicWidgetsService;
}

let publicApi: PublicAPI | undefined;

export function getPublicApi(): PublicAPI {
  if (publicApi === undefined) {
    throw Error(
      'public Api has not yet been initialized, call `initPublicServices` first',
    );
  }
  return publicApi;
}

const initPublicServicesV3ToV2Mapping = (
  httpClient: IHttpClient,
  instance: string,
  v3MappingBaseUrl: string,
) => {
  publicApi = {
    purchase: createPublicPurchaseService(httpClient),
    channelService: new PublicChannelService(httpClient, v3MappingBaseUrl),
    videoService: new PublicVideoApiService(httpClient, v3MappingBaseUrl),
    channelVideos: new PublicChannelVideosService(httpClient, v3MappingBaseUrl),
    youtubeVideosService: new YoutubeVideosService(httpClient),
    publicWidgetsService: new PublicWidgetsService(),
  };
};

export function initPublicServices(
  httpClient: IHttpClient,
  instance: string,
  v3ToV2MappingBaseUrl: string,
) {
  initPublicServicesV3ToV2Mapping(httpClient, instance, v3ToV2MappingBaseUrl);
}
