import { IWixAPI } from '@wix/yoshi-flow-editor';
import { WidgetData } from './getWidgetData';

const KEY = 'vodWidgetData';
const getKey = (compId: string) => `${KEY}-${compId}`;
const isSSR = (wixCodeApi: IWixAPI): boolean =>
  wixCodeApi.window.rendering.env === 'backend';

// omit translations since they are passed to FE controller, no need to store and JSON-ise them twice
type WarmupWidgetData = Omit<WidgetData, '__TRANSLATIONS__'>;

export const getWidgetWarmupData = (
  wixCodeApi: IWixAPI,
  compId: string,
): WarmupWidgetData | null => {
  if (isSSR(wixCodeApi)) {
    return null;
  }
  const stringData = wixCodeApi.window.warmupData.get(getKey(compId));

  return stringData ? JSON.parse(stringData) : null;
};

export const setWidgetWarmupData = (
  wixCodeApi: IWixAPI,
  compId: string,
  widgetData: WarmupWidgetData,
) => {
  if (isSSR(wixCodeApi)) {
    wixCodeApi.window.warmupData.set(
      getKey(compId),
      JSON.stringify(widgetData),
    );
  }
};
