import _ from 'lodash';
import { handleActions } from 'redux-actions';

import { NAMES as VIDEO_URLS_GET } from '../actions/video-urls/fetch';
import { NAMES as VIDEO_URLS_CLEAR } from '../actions/video-urls/clear';

import CACHE_ITEM_STATUS from '../../constants/ulrs-cache-status';

const defaultState = {};

function setPending(state, { payload }) {
  const {
    params: {
      videoItem: { id },
    },
  } = payload;

  return {
    ...state,
    [id]: {
      status: CACHE_ITEM_STATUS.PENDING,
    },
  };
}

function removeOnFail(state, { payload }) {
  const {
    params: {
      videoItem: { id },
    },
  } = payload;

  return {
    ...state,
    [id]: {
      status: CACHE_ITEM_STATUS.FAILED,
    },
  };
}

function saveToCache(state, { payload }) {
  const urls = payload.response;
  const {
    params: {
      videoItem: { id },
    },
  } = payload;
  return {
    ...state,
    [id]: {
      status: CACHE_ITEM_STATUS.RESOLVED,
      urls,
    },
  };
}

function clearCacheForItem(state, { payload }) {
  const id = payload;

  return _.omit(state, id);
}

function clearCache() {
  return {};
}

export default handleActions(
  {
    [VIDEO_URLS_GET.START]: setPending,
    [VIDEO_URLS_GET.SUCCESS]: saveToCache,
    [VIDEO_URLS_GET.FAIL]: removeOnFail,
    [VIDEO_URLS_CLEAR.ALL]: clearCache,
    [VIDEO_URLS_CLEAR.FOR_ITEM]: clearCacheForItem,
  },
  defaultState,
);
