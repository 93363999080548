import { handleActions } from 'redux-actions';
import { NAMES } from '../actions/full-screen-modal';

export type FullScreenModalState = {
  lightbox: boolean;
};

export default handleActions<FullScreenModalState, void>(
  {
    [NAMES.OPEN_FULL_SCREEN_MODAL_VIDEO]: () => ({ lightbox: true }),
    [NAMES.CLOSE_FULL_SCREEN_MODAL_VIDEO]: () => ({ lightbox: false }),
  },
  {
    lightbox: false,
  },
);
