import _ from 'lodash';
import { handleActions } from 'redux-actions';

import { SELECTED_VIDEO_ACTIONS } from '../actions/select-video';
import { NAMES as CHANNEL_VIDEOS_LIST } from '../actions/channel/videos/list-public';

import {
  RESET_LAZY_CHANNEL_VIDEOS,
  SET_LAZY_CHANNEL_VIDEOS_PAGE_INDEX,
  SET_LAZY_CHANNEL_VIDEOS_VIDEO_INDEX,
  PREPEND_LAZY_VIDEO_IDS,
} from './actions';

const defaultState = {
  prependedIds: [],
  ids: [],
  cursor: null,
  perPage: 0,
  isFetching: false,
  currentPageIndex: 0,
  currentVideoIndex: 0,
};

const updateIds = (state, { payload }) => {
  const idsWithoutPrepended = _.reject(payload.response.data.result, (id) =>
    _.includes(state.prependedIds, id),
  );

  return {
    ...state,
    ids: [...state.ids, ...idsWithoutPrepended],
    cursor: payload.response.paging.cursor,
    isFetching: false,
  };
};

const prependVideoIds = (state, { payload }) => ({
  ...state,
  prependedIds: [...state.prependedIds, ..._.castArray(payload)],
});

const setFetching = (isFetching) => (state) => ({ ...state, isFetching });

const setPageIndex = (state, { payload }) => ({
  ...state,
  currentPageIndex: payload,
});

const setVideoIndex = (state, { payload }) => ({
  ...state,
  currentVideoIndex: payload,
});

const resetVideoIndex = (state) => ({
  ...state,
  currentVideoIndex: 0,
});

const setVideoIndexFromVideoId = (state, { payload }) => ({
  ...state,
  currentVideoIndex: Math.max(0, _.indexOf(state.ids, payload, 0)),
});

export default handleActions(
  {
    [CHANNEL_VIDEOS_LIST.START]: setFetching(true),
    [CHANNEL_VIDEOS_LIST.FAIL]: setFetching(false),
    [CHANNEL_VIDEOS_LIST.SUCCESS]: updateIds,
    [PREPEND_LAZY_VIDEO_IDS]: prependVideoIds,

    [SET_LAZY_CHANNEL_VIDEOS_PAGE_INDEX]: setPageIndex,
    [SET_LAZY_CHANNEL_VIDEOS_VIDEO_INDEX]: setVideoIndex,

    [SELECTED_VIDEO_ACTIONS.SET]: setVideoIndexFromVideoId,
    [SELECTED_VIDEO_ACTIONS.RESET]: resetVideoIndex,

    [RESET_LAZY_CHANNEL_VIDEOS]: _.constant(defaultState),
  },
  defaultState,
);
