import { handleActions } from 'redux-actions';
import {
  NAMES as SERVER_CHANNEL_VIDEOS_LIST,
  ListPublicChannelVideosAction,
} from '../../actions/channel/videos/list-public';

export default handleActions<string[], any>(
  {
    [SERVER_CHANNEL_VIDEOS_LIST.START]: () => [],
    [SERVER_CHANNEL_VIDEOS_LIST.SUCCESS]: (
      state,
      { payload }: ListPublicChannelVideosAction,
    ) => {
      return payload.response?.data.result || [];
    },
  },
  [],
);
