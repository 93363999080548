import { DEFAULT_LOCALE } from './config';
import parseQueryParams from '../../utils/get-query-params';

// Language priority (high to low):
// 1) Multilingual ('lang ') query param
// 2) Site language
// 3) Default locale
export const getAppLocale = ({ siteLocale }: { siteLocale?: string } = {}) => {
  const queryParams =
    typeof window === 'undefined' ? {} : parseQueryParams(window.location.href);

  return queryParams.lang || siteLocale || DEFAULT_LOCALE;
};
