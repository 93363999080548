import { handleActions } from 'redux-actions';
import { SELECTED_VIDEO_ACTIONS } from '../actions/select-video';
import { NAMES as VIDEO_PLAYBACK_ACTIONS } from '../actions/player/change-playback-status';

function markAsTouched() {
  return true;
}

function markAsNotTouched() {
  return false;
}

export default handleActions<boolean>(
  {
    [SELECTED_VIDEO_ACTIONS.SET]: markAsTouched,
    [SELECTED_VIDEO_ACTIONS.RESET]: markAsNotTouched,
    [VIDEO_PLAYBACK_ACTIONS.REQUEST_PLAY]: markAsTouched,
  },
  false,
);
