import { normalize, NormalizedSchema } from 'normalizr';
import {
  channel,
  video,
  arrayOfVideos,
  VideoSchema,
  ChannelSchema,
} from './schemas';

export function normalizeVideo(data: any) {
  return normalize(data, video);
}

type VideosEntities = { videos: Record<string, any> };
type VideosResult = string[];
export type NormalizedArrayOfVideos = NormalizedSchema<
  VideosEntities,
  VideosResult
>;

export function normalizeArrayOfVideos(data: any): NormalizedArrayOfVideos {
  return normalize<VideoSchema, VideosEntities, VideosResult>(
    data,
    arrayOfVideos,
  );
}

type ChannelEntities = {
  channels: Record<string, any>;
  videos?: Record<string, any>;
};
type ChannelResult = Record<string, string>;
export type NormalizedChannels = NormalizedSchema<
  ChannelEntities,
  ChannelResult
>;

export function normalizeChannel(data: any): NormalizedChannels {
  return normalize<ChannelSchema, ChannelEntities, ChannelResult>(
    data,
    channel,
  );
}
