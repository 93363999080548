import { CorrelatedBiEventsChain } from './chain';

export const playbackCorrelationChain = new CorrelatedBiEventsChain(
  'widget.vid.clicked',
  'player-play-requested',
  'player-play-done',
  'widget.vid.start.play',
  'player.debug.info',
  'widget.vid.played',
  'widget.vid.fullScreen.clicked',
  'widget.vid.error',
  'player-error',
  'widget.vid.fullScreen.entered',
);
