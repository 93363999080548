import _ from 'lodash';
import { guid } from '@wix/wix-vod-shared/common';

export class CorrelatedBiEventsChain {
  constructor(resetEventName, ...eventNames) {
    this.eventNames = [resetEventName, ...eventNames];
    this.resetEventName = resetEventName;
    this.correlationId = guid();
  }

  updateCorrelationId() {
    this.correlationId = guid();
  }

  isSupportedEvent(eventName) {
    return _.includes(this.eventNames, eventName);
  }

  isResetEvent(eventName) {
    return eventName === this.resetEventName;
  }

  getCorrelationId(eventName) {
    if (!this.isSupportedEvent(eventName)) {
      return null;
    }

    if (this.isResetEvent(eventName)) {
      this.updateCorrelationId();
    }

    return this.correlationId;
  }

  getLastCorrelationId = () => this.correlationId;
}
