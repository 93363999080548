import { ControllerParams } from '@wix/yoshi-flow-editor';

export type LiveHandlers = {
  pingCounterService: (streamPingUrl: string) => void;
};

export function createLiveHandlers(context: ControllerParams): LiveHandlers {
  const {
    flowAPI: { httpClient },
  } = context;

  return {
    pingCounterService: async (streamPingUrl: string) => {
      await httpClient.get(streamPingUrl);
    },
  };
}
