import { createAction } from 'redux-actions';

export const SHARE_OVERLAY_ACTIONS = {
  TOGGLE_SHARE_CHANNEL: 'CLIENT.SHARE_OVERLAY.TOGGLE_SHARE_CHANNEL',
  TOGGLE_SHARE_VIDEO: 'CLIENT.SHARE_OVERLAY.TOGGLE_SHARE_VIDEO',
  CLOSE: 'CLIENT.SHARE_OVERLAY.CLOSE',
};

export const toggleShareChannelOverlay = createAction(
  SHARE_OVERLAY_ACTIONS.TOGGLE_SHARE_CHANNEL,
);
export const toggleShareVideoOverlay = createAction(
  SHARE_OVERLAY_ACTIONS.TOGGLE_SHARE_VIDEO,
);
export const closeShareOverlay = createAction(SHARE_OVERLAY_ACTIONS.CLOSE);
