import _ from 'lodash';
import { createSelector } from 'reselect';
import { getHydratedData } from '../redux/hydrated-data/hydrated-data';
import { RootState } from '../redux/root-state';
import * as viewModeSelectors from './view-mode';

export type SiteUser = {
  id: string;
  email: string;
};

export function getCurrentSiteUser(state: RootState) {
  const isSiteMode = viewModeSelectors.isSiteMode(state);
  const { currentSiteUser } = getHydratedData(state);

  if (!isSiteMode) {
    return null;
  }

  return currentSiteUser;
}

export const getCurrentUserEmail = createSelector(
  getCurrentSiteUser,
  (currentSiteUser) => _.get(currentSiteUser, 'email', ''),
);
