import { schema } from 'normalizr';

export type ChannelSchema = any;
const channel = new schema.Entity('channels');
const paymentInfoChannel = new schema.Entity(
  'channels',
  {},
  {
    idAttribute: 'listId',
  },
);

const arrayOfChannels = [channel];

export type VideoSchema = any;
const video = new schema.Entity<VideoSchema>('videos');
const arrayOfVideos = [video];

channel.define({
  featuredItem: video,
});

video.define({
  usedInLists: arrayOfChannels,
});

export { channel, paymentInfoChannel, arrayOfChannels, video, arrayOfVideos };
