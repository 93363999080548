import _ from 'lodash';
import { Channel, ChannelType } from '../redux/types';
import { demoChannel, demoChannelEditor } from '../constants/demo-channel';

export function isVODChannel(channelData: Channel) {
  return channelData.channelType === ChannelType.CHANNEL;
}

export function isYouTubeFeed(channelData: Channel) {
  return channelData.channelType === ChannelType.YOUTUBE_FEED;
}

export function hasTags(channelData: Channel) {
  const tags = _.get(channelData, 'statsInfo.tags');
  return !_.isEmpty(tags);
}

export function hasVideos(channelData: Channel) {
  return channelData.videosCount > 0;
}

export function isDemoChannel(channelId: string) {
  return _.includes([demoChannel.id, demoChannelEditor.id], channelId);
}
