import { createAction } from 'redux-actions';
import { closeSlideMenu } from '../../../mobile/redux/actions/slide-menu';

export const VISIT_CHANNEL = 'CLIENT.VISIT-CHANNEL';
const VISIT_CHANNEL_ACTION = createAction(VISIT_CHANNEL);

export const OPEN_VIDEO_PAGE = 'CLIENT.OPEN.VIDEO-PAGE';
const OPEN_VIDEO_PAGE_ACTION = createAction(OPEN_VIDEO_PAGE);

export const visitChannel = (openMobileOverlay) => {
  return function (dispatch) {
    dispatch(VISIT_CHANNEL_ACTION());
    dispatch(closeSlideMenu());
    openMobileOverlay();
  };
};

export const openVideoPage = (videoId, openMobileOverlay) => {
  return function (dispatch) {
    dispatch(OPEN_VIDEO_PAGE_ACTION());
    dispatch(closeSlideMenu());
    openMobileOverlay(`/video/${videoId}`);
  };
};
