import type { ActionCreator } from '../redux.types';
import { createAction } from 'redux-actions';
import { getHydratedData } from '../hydrated-data/hydrated-data';
import { updateWidgetSingleVideo } from '../initial-data-desktop';

// TODO: specify SetAppSettingsPayload fields
export type SetAppSettingsPayload = any;

export const APP_SETTINGS_UPDATE = 'CLIENT.APP_SETTINGS.SET';
export const setAppSettings =
  createAction<SetAppSettingsPayload>(APP_SETTINGS_UPDATE);

export const updateAppSettings: ActionCreator<SetAppSettingsPayload> =
  (styleParams: any) => async (dispatch) => {
    dispatch(setAppSettings(styleParams));
    await dispatch(updateWidgetSingleVideo());
  };

export const setInitialAppSettings: ActionCreator<SetAppSettingsPayload> =
  (styleParams: any) => (dispatch, getState) => {
    const state = getState();
    const { siteUrl, locale } = getHydratedData(state);

    const settings = {
      ...styleParams,
      siteUrl,
      locale,
    };

    dispatch(setAppSettings(settings));
  };
