import { isVODChannel } from '../../../../utils/channel-helpers';
import {
  createAsyncActions,
  AsyncAction,
} from '../../../helpers/create-async-actions';
import { getHydratedData } from '../../../hydrated-data/hydrated-data';
import { getPublicApi } from '../../../../api/public';
import { ActionCreator } from '../../../redux.types';
import { getChannelById } from '../../../../selectors/channels';

type GetPublicVideoParams = {
  channelId: string;
  videoId: string;
};
type GetPublicVideoResponse = any;
export type GetPublicVideoAction = AsyncAction<
  GetPublicVideoResponse,
  GetPublicVideoParams
>;

const ACTIONS = createAsyncActions<
  GetPublicVideoResponse,
  GetPublicVideoParams
>('SERVER.CHANNEL.VIDEO.GET');
export const GET_PUBLIC_VIDEO_ACTIONS = ACTIONS.NAMES;
const { START, SUCCESS, FAIL } = ACTIONS;

export const getPublicVideo: ActionCreator =
  (channelId, videoId) => async (dispatch, getState) => {
    const state = getState();
    const params = { channelId, videoId };
    const channel = getChannelById(state, channelId);
    const {
      singleVideoId,
      singleVideo: hydratedSingleVideo,
      templateMetaSiteId,
    } = getHydratedData(state);

    dispatch(START(params));
    let videoData;
    const publicApi = getPublicApi();

    try {
      if (singleVideoId === videoId && hydratedSingleVideo) {
        videoData = hydratedSingleVideo;
      } else if (channel && !isVODChannel(channel)) {
        videoData = (
          await publicApi.youtubeVideosService.getYoutubeVideoById(videoId)
        ).data;
      } else {
        try {
          videoData = (
            await publicApi.channelVideos.getChannelVideoById(
              channelId,
              videoId,
              {
                params: {
                  metaSiteId: templateMetaSiteId,
                },
              },
            )
          ).data;
        } catch (error: any) {
          if (error.status === 404) {
            videoData = (
              await publicApi.youtubeVideosService.getYoutubeVideoById(videoId)
            ).data;
          }
        }
      }

      dispatch(SUCCESS(params, videoData));
      return videoData;
    } catch (error) {
      dispatch(
        FAIL(params, error, {
          analytics: { type: 'error', name: 'shared.channel.video.get' },
        }),
      );
    }
  };
