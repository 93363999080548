import _ from 'lodash';

const perf = global.vodPerformance || {
  now: _.noop,

  addVar: _.noop,
  addTimestamp: _.noop,

  stamps: {},
  vars: {},
};

const { addVar, addTimestamp, stamps, vars, now } = perf;

export const addSingleMeasurement = (
  name,
  fromTimestamp = 'navigationStart',
) => {
  if (!stamps[name]) {
    addTimestamp(name, now());
  }

  if (!vars[name]) {
    addVar(name, now() - stamps[fromTimestamp]);
  }
};

export default perf;
