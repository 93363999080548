import { createSelector } from 'reselect';
import { VIEW_MODES } from '@wix/wix-vod-constants/common';
import { getHydratedData } from '../redux/hydrated-data/hydrated-data';

export const getViewMode = createSelector(
  getHydratedData,
  (data) => data.viewMode,
);

const isMatchingViewMode = (expectedViewMode) =>
  createSelector(getViewMode, (viewMode) => viewMode === expectedViewMode);

export const isPreviewMode = isMatchingViewMode(VIEW_MODES.PREVIEW);
export const isEditorMode = isMatchingViewMode(VIEW_MODES.EDITOR);
export const isSiteMode = isMatchingViewMode(VIEW_MODES.SITE);
export const isStandalone = isMatchingViewMode(VIEW_MODES.STANDALONE);
