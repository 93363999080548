import { handleActions } from 'redux-actions';
import {
  FetchVideoCardsAction,
  NAMES as GET_VIDEO_CARDS,
} from '../actions/video-cards/get';
import { VIDEO_CARDS_CLEANUP } from '../actions/video-cards/cleanup';
import { getResizedImageUrl } from '@wix/wix-vod-shared/common';
import { t } from '../../utils/translation';
import { ItemType, VideoCard, VideoCardType } from '../types';

export type VideoCardsState = VideoCard[];

const defaultState: VideoCardsState = [];

const THUMBNAIL_SIZE = 292;

export default handleActions<VideoCardsState, any>(
  {
    [GET_VIDEO_CARDS.SUCCESS]: (cards, { payload }: FetchVideoCardsAction) => {
      const data = payload.response as any;
      return data.map((card: any) => {
        if (card.dateStartLive) {
          const datetime = new Date(card.dateStartLive).toLocaleTimeString([], {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
          });

          card.liveLabel = `${t('widget.video-cards.live-label')}: ${datetime}`;
        }

        if (
          card.type === VideoCardType.Video ||
          card.type === VideoCardType.RecentVideo
        ) {
          if (card.itemType === ItemType.ItemLive) {
            card.buttonLabel = t('widget.video-cards.watch-live');
          } else {
            card.buttonLabel = t('widget.video-cards.watch-next');
          }
        }

        if (card.type === VideoCardType.Channel) {
          card.buttonLabel = t('widget.video-cards.watch-more');
        }

        return {
          ...card,
          thumbnailUrl: getResizedImageUrl({
            url: card.thumbnailUrl,
            width: THUMBNAIL_SIZE,
            height: THUMBNAIL_SIZE,
            params: { lg: 1 },
          }),
        };
      });
    },
    [VIDEO_CARDS_CLEANUP]: () => defaultState,
  },

  defaultState,
);
