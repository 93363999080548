import _ from 'lodash';
import { createSelector } from 'reselect';
import { getVideoIdFromUrl } from './query-params';
import { getVideosGroupedByIds } from './videos';
import { getCurrentVideoId, isSingleVideo } from './video';

export const getVideoIdToSelect = createSelector(
  [isSingleVideo, getCurrentVideoId, getVideoIdFromUrl],
  (isSingle, singleVideoId, videoIdFromUrl) =>
    isSingle ? singleVideoId : videoIdFromUrl,
);

export const videoToSelectExists = createSelector(
  [getVideoIdToSelect, getVideosGroupedByIds],
  (videoIdToSelect, videos) => {
    return !_.isEmpty(videos[videoIdToSelect]);
  },
);
