import _ from 'lodash';
import { handleActions } from 'redux-actions';
import { demoChannel } from '../../constants/demo-channel';
import { SetAppSettingsPayload } from '../actions/app-settings';
import { NAMES as CHANNEL_GET } from '../actions/channel/get-public';
import { SET_CURRENT_CHANNEL_ID } from '../actions/current-channel-id';

export type CurrentChannelIdState = {
  currentChannelId: string | null;
  prevChannelIdFromSettings?: string | null;
};

export default handleActions<CurrentChannelIdState, SetAppSettingsPayload>(
  {
    [SET_CURRENT_CHANNEL_ID]: (state, { payload }) => {
      const nextState: CurrentChannelIdState = {
        prevChannelIdFromSettings: state.currentChannelId,
        currentChannelId: payload,
      };

      return nextState;
    },
    [CHANNEL_GET.FAIL]: (state, { payload }) => {
      if (_.get(payload, 'response.error.code') === -7710) {
        return { ...state, currentChannelId: demoChannel.id };
      }

      return state;
    },
  },
  { currentChannelId: null, prevChannelIdFromSettings: undefined },
);
