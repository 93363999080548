const PREFIX = '@WORKER_ACTION';
const UNIQUE_FN_KEY = '____worker-fn-proxy-id';

const FUNCTION_EVENT_NAME = 'FUNCTION_CALL_EVENT';
function isProxyFn(val) {
  return (
    val !== null && typeof val === 'object' && val[UNIQUE_FN_KEY] !== undefined
  );
}

const createEvent = (() => {
  const getUniqueId = (() => {
    let id = 0;
    return () => ++id;
  })();

  return (name, data) => {
    return { id: getUniqueId(), name, data };
  };
})();

function applyForAction(action, argCondition, apply) {
  if (!isWorkerAction(action) && !isProxyAction(action)) {
    return action;
  }
  let { resolve, reject, args, ...other } = action;

  args = args.map((arg) => {
    if (!argCondition(arg)) {
      return arg;
    }
    return apply(arg);
  });

  if (other.isAsync) {
    resolve = apply(resolve);
    reject = apply(reject);
  }

  return { ...other, resolve, reject, args };
}

export function restoreAction({ publishEvent, action }) {
  const restoreFn =
    (fn) =>
    (...args) =>
      publishEvent(
        createEvent(FUNCTION_EVENT_NAME, {
          functionId: fn[UNIQUE_FN_KEY],
          args,
        }),
      );

  return applyForAction(action, isProxyFn, restoreFn);
}

export function createEventPublisher({ setProps }) {
  let events = [];
  const publishEvent = (event) => {
    events = events.concat([event]);
    setProps({ events });
  };

  const consumeEvents = (eventsIds) => {
    const eventIdSet = new Set(eventsIds);
    events = events.filter((ev) => !eventIdSet.has(ev.id));
  };

  const getEvents = () => events;

  return { publishEvent, consumeEvents, getEvents };
}

export function consumeEvents(props, cb) {
  const events = props.events || [];

  events.forEach((ev) => cb(ev));

  props.consumeEvents(events.map((ev) => ev.id));
}

export function createFunctionProxy() {
  const functionsMap = new Map();

  const getUniqueId = (() => {
    let id = 0;
    return () => ++id;
  })();

  function proxyfyFn(fn) {
    const fnId = getUniqueId();
    functionsMap.set(fnId, fn);
    return { [UNIQUE_FN_KEY]: fnId };
  }

  function serializeAction(action) {
    return applyForAction(
      action,
      (arg) => typeof arg === 'function',
      proxyfyFn,
    );
  }

  function callFunction(event) {
    if (event.name !== FUNCTION_EVENT_NAME) {
      return;
    }
    const { functionId, args } = event.data;

    const origFn = functionsMap.get(functionId);
    if (!origFn) {
      console.error('calling callback function multiple times');

      return;
    }
    functionsMap.delete(functionId);

    origFn(...args);
  }

  return { serializeAction, callFunction, proxyfyFn };
}

export function createWorkerAction(type) {
  const actionType = `${PREFIX}/${type}`;
  const actionCreator = (...args) => ({
    type: actionType,
    args,
  });
  actionCreator.type = actionType;
  return actionCreator;
}

const createBaseProxy = (() => {
  return (type, fn) => fn;
})();

export const createProxy = createBaseProxy;
export const createAsyncProxy = (type, fn) =>
  createBaseProxy(type, fn, { isAsync: true });

export function isProxyActionCreator() {
  return true;
}

export function isProxyAction(action) {
  return typeof action === 'object' && action.type.startsWith('@PROXY/');
}

export function isWorkerAction(action) {
  return action.type.startsWith(PREFIX);
}

export function createHandler(handlers) {
  const mapping = {};
  handlers.forEach(([actionCreator, handler]) => {
    if (!actionCreator.type) {
      throw new Error('bad actionCreator, create it with createWorkerAction');
    }
    if (mapping[actionCreator.type]) {
      throw new Error('not unique type');
    }
    mapping[actionCreator.type] = handler;
  });

  return (action) => {
    if (!isWorkerAction(action)) {
      return;
    }
    const handler = mapping[action.type];
    if (!handler) {
      console.warn(`can't find handler for action: ${JSON.stringify(action)}`);
      return;
    }
    const args = action.args || [];
    handler(...args);
  };
}

export const createWorkerHandlerMiddleware =
  (handler) => () => (next) => (action) => {
    if (isWorkerAction(action)) {
      handler(action);
      return;
    }
    return next(action);
  };
