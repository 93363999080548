import _ from 'lodash';

const TRANSACTION_START_TYPE = 'requested';

const userTransactions = {
  status: {
    // predefined events
    'fullscreenModal.load': true,
  },

  getEventName({ eventName }) {
    return eventName.split('.').slice(0, -1).join('.');
  },

  getTransactionType({ eventName }) {
    return _.last(eventName.split('.'));
  },

  isTransaction({ eventName, previousEventName }) {
    const isTransactionStartEvent =
      userTransactions.getTransactionType({ eventName }) ===
      TRANSACTION_START_TYPE;
    return isTransactionStartEvent || previousEventName;
  },

  hasActive(eventName) {
    return Boolean(userTransactions.status[eventName]);
  },

  start(eventName) {
    userTransactions.status[eventName] = true;
  },

  complete(eventName) {
    return delete userTransactions.status[eventName];
  },

  getTransactionResult(event) {
    const eventName = userTransactions.getEventName(event);
    const transactionType = userTransactions.getTransactionType(event);

    if (transactionType === TRANSACTION_START_TYPE) {
      if (!userTransactions.hasActive(eventName)) {
        userTransactions.start(eventName);
      }
      return true;
    }

    if (!userTransactions.hasActive(eventName)) {
      return false;
    }

    return userTransactions.complete(eventName);
  },
};

export default userTransactions;
