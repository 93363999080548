import { DEMO_CHANNEL_ID } from '@wix/wix-vod-constants/env';

export const demoChannel = {
  id: DEMO_CHANNEL_ID,
  title: 'Wix Demo Channel',
};

export const demoChannelEditor = {
  id: 'a1ca9dac7ee44d52a41868329471105b',
  title: 'Wix Editor Demo Channel',
};
