import { handleActions } from 'redux-actions';
import {
  CHANNEL_PAYMENT_ACTIONS,
  GetPaymentInfoAction,
} from '../../actions/channel/payment';
import { DgsInfo } from '../../types';

const defaultState = {
  hasAccessToChannelByPricingPlan: false,
};

export type PaymentInfoState = {
  dgsInfo?: DgsInfo;
  listId?: string;
  hasAccessToChannelByPricingPlan: boolean;
};

export default handleActions<PaymentInfoState, any>(
  {
    [CHANNEL_PAYMENT_ACTIONS.NAMES.SUCCESS]: (
      state,
      { payload }: GetPaymentInfoAction,
    ) => {
      const channelPaymentData = payload.response?.channel;
      const hasAccessToChannelByPricingPlan = Boolean(
        payload.response?.hasAccessToChannelByPricingPlan,
      );

      return {
        ...state,
        ...channelPaymentData,
        hasAccessToChannelByPricingPlan,
      };
    },
  },
  defaultState,
);
