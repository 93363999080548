import _ from 'lodash';
import { handleActions } from 'redux-actions';
import {
  STATUSES,
  CREATE_ORDER,
  SELECT_METHOD,
  INIT_CHECKOUT,
  setError,
  statusChange,
} from '../actions/payment.constants';

const initialState = {
  config: null,
  error: null,
  orderId: null,
  paymentType: null,
  paymentMethods: null,
  selectedMethod: null,
  checkoutInitializationStatus: STATUSES.IDLE,
};

function handleError(state, { payload: error }) {
  if (error && error.params) {
    error = error.params;
  }
  if (_.isError(error)) {
    error = error.message;
  }
  if (_.isObject(error)) {
    error = _.get(error, 'data.error_description');
  }
  if (!error || !_.isString(error)) {
    error = 'unknown_error';
  }
  return { ...state, status: 'ERROR', error };
}

function handleStatusChange(state, { payload: status }) {
  return { ...state, status };
}

export default handleActions(
  {
    [INIT_CHECKOUT.START](state) {
      return {
        ...state,
        checkoutInitializationStatus: STATUSES.PENDING,
      };
    },

    [INIT_CHECKOUT.SUCCESS](state) {
      return {
        ...state,
        checkoutInitializationStatus: STATUSES.DONE,
      };
    },

    [INIT_CHECKOUT.FAIL](state, ...args) {
      return {
        ...state,
        checkoutInitializationStatus: STATUSES.FAIL,
        ...handleError(state, ...args),
      };
    },

    [CREATE_ORDER.START](state, { payload }) {
      return {
        ...state,
        paymentType: _.get(payload, 'params.paymentType'),
        orderId: null,
        error: null,
        paymentMethods: null,
        selectedMethod: null,
      };
    },

    [CREATE_ORDER.SUCCESS](state, { payload }) {
      return {
        ...state,
        orderId: _.get(payload, 'params.orderId'),
      };
    },

    [CREATE_ORDER.FAIL]: handleError,

    [setError]: handleError,

    [SELECT_METHOD](state, { payload }) {
      return {
        ...state,
        error: null,
        selectedMethod: payload,
      };
    },
    [statusChange]: handleStatusChange,
  },
  initialState,
);
