import { IWixAPI } from '@wix/yoshi-flow-editor';
import { VIEW_MODES } from '@wix/wix-vod-constants/common';
import { PRODUCTION_HOSTS } from '@wix/wix-vod-constants/env';
import { getViewMode } from './getViewMode';

function getV3ToV2MappingBaseUrlPrefix(
  wixCodeApi: IWixAPI,
  { isModal = false }: { isModal: boolean },
) {
  const isSite = getViewMode(wixCodeApi) === VIEW_MODES.SITE;
  const editorWixApps = PRODUCTION_HOSTS.editorWixApps;
  if (!isSite) {
    return editorWixApps;
  }
  const { baseUrl } = wixCodeApi.location;
  if (!baseUrl) {
    return editorWixApps;
  }
  if (isModal) {
    return baseUrl;
  }
  try {
    return new URL(baseUrl).pathname;
  } catch (err: unknown) {
    // to make it more resilient in case platform gives us a relative or invalid url for whatever reason we don't have to fail
    return editorWixApps;
  }
}

export function getV3ToV2MappingBaseUrl(
  wixCodeApi: IWixAPI,
  { isModal }: { isModal: boolean },
) {
  const prefix = getV3ToV2MappingBaseUrlPrefix(wixCodeApi, { isModal });
  return `${
    prefix.endsWith('/') ? prefix.slice(0, -1) : prefix
  }/_api/vod/public/v3-to-v2`;
}
