import _ from 'lodash';
import { RootState } from '../redux/root-state';

export const getChannelId = (state: RootState) =>
  _.get(state, 'channelInfo.info.id');

export const getCurrentChannelId = (state: RootState) =>
  state.currentChannelId.currentChannelId;

export const getChannelById = (state: RootState, channelId: string) =>
  state.entities.channels[channelId];
