import _ from 'lodash';
import { handleActions } from 'redux-actions';
import { APP_SETTINGS_UPDATE } from '../actions/app-settings';

export default handleActions(
  {
    [APP_SETTINGS_UPDATE]: (state, { payload }) => {
      return _.merge({}, state, payload);
    },
  },
  null,
);
