import { ControllerParams } from '@wix/yoshi-flow-editor';
import { PRODUCTION_HOSTS } from '@wix/wix-vod-constants/env';

type GetAnnotoTokenResponse = {
  token: string;
};

export type AnnotoHandlers = {
  requestAnnotoToken: (userId: string, metaSiteId: string) => void;
};

export type AnnotoState = {
  annotoToken?: string;
};

export function createAnnotoHandlers(
  setState: (state: AnnotoState) => void,
  context: ControllerParams,
): AnnotoHandlers {
  return {
    requestAnnotoToken: async (userId: string, metaSiteId: string) => {
      const {
        flowAPI: { httpClient },
      } = context;

      const response = await httpClient.get<GetAnnotoTokenResponse>(
        PRODUCTION_HOSTS.pixWix + '/annoto/token',
        {
          params: {
            member_id: userId,
            metasite_id: metaSiteId,
          },
        },
      );

      setState({
        annotoToken: response.data.token,
      });
    },
  };
}
