import { handleActions, Reducer } from 'redux-actions';
import { SEARCH_QUERY_FIELDS } from '../../constants/search-query-fields';
import { NAMES as CHANNEL_VIDEOS_LIST } from '../actions/channel/videos/list-public';
import { SEARCH_ACTIONS } from '../actions/search';

export type SearchState = {
  searchQuery: string;
  searchQueryField: SEARCH_QUERY_FIELDS;
  isSearching: boolean;
};

export type SearchPayload = {
  searchQuery: string;
  searchQueryField: SEARCH_QUERY_FIELDS;
};

const DEFAULT_STATE = {
  searchQuery: '',
  searchQueryField: SEARCH_QUERY_FIELDS.QUERY,
  isSearching: false,
};

const updateSearch: Reducer<SearchState, SearchPayload> = (
  state,
  { payload },
) => {
  const { searchQuery, searchQueryField } = payload;

  return {
    ...state,
    searchQuery,
    searchQueryField,
    isSearching:
      searchQuery !== state.searchQuery ||
      searchQueryField !== state.searchQueryField,
  };
};

const markSearchComplete = (state: SearchState) => ({
  ...state,
  isSearching: false,
});

export default handleActions<SearchState, SearchPayload>(
  {
    [SEARCH_ACTIONS.UPDATE_SEARCH]: updateSearch,
    [SEARCH_ACTIONS.RESET_SEARCH]: () => DEFAULT_STATE,
    [CHANNEL_VIDEOS_LIST.SUCCESS]: markSearchComplete,
    [CHANNEL_VIDEOS_LIST.FAIL]: markSearchComplete,
  },
  DEFAULT_STATE,
);
