export default (biHandlers) =>
  ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    const previousState = getState();
    const returnValue = next(action); // eslint-disable-line callback-return
    const { type } = action;
    const handler = biHandlers[type];

    if (handler) {
      handler({
        dispatch,
        state: getState(),
        previousState,
        action,
      });
    }

    return returnValue;
  };
