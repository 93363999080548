import { handleActions } from 'redux-actions';

import { NAMES as VIDEO_SAVED_TIME_ACTIONS } from '../../actions/player/set-saved-time';

export default handleActions(
  {
    [VIDEO_SAVED_TIME_ACTIONS.SET]: (state, { payload }) => payload,
  },
  0,
);
