import { createAction } from 'redux-actions';
import { SEARCH_QUERY_FIELDS } from '../../constants/search-query-fields';
import { SearchPayload } from '../reducers/search';

export const SEARCH_ACTIONS = {
  UPDATE_SEARCH: 'CLIENT.SEARCH.UPDATE_SEARCH',
  RESET_SEARCH: 'CLIENT.SEARCH.RESET_SEARCH',
};

export const updateSearch = createAction<SearchPayload>(
  SEARCH_ACTIONS.UPDATE_SEARCH,
);
export const resetSearch = createAction<SearchPayload>(
  SEARCH_ACTIONS.RESET_SEARCH,
);

export const searchByString = (value: string) =>
  updateSearch({
    searchQuery: value,
    searchQueryField: SEARCH_QUERY_FIELDS.QUERY,
  });
export const searchByTag = (value: string) =>
  updateSearch({
    searchQuery: value,
    searchQueryField: SEARCH_QUERY_FIELDS.TAG,
  });
export const searchByCategory = (value: string) =>
  updateSearch({
    searchQuery: value,
    searchQueryField: SEARCH_QUERY_FIELDS.CATEGORY,
  });
