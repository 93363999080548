import _ from 'lodash';
import { createSelector } from 'reselect';
import {
  getThumbnailSpacing,
  getVideosInRowCount,
  getContainerMargins,
  getStretchToFullWidth,
  canShowVideoListItemContentBelow,
} from '../../selectors/app-settings';
import { WIDTH_CONSTRAINTS } from '../../constants/thumbnail-sizes';
import { GRID_MARGINS, MAX_SPACING } from './constants';

const getWorkingAreaWidth = createSelector(
  (state, widgetWidth) => widgetWidth,
  getContainerMargins,
  getStretchToFullWidth,
  (width, containerMargins, isFullWidth) => {
    if (!width) {
      return 0;
    }
    const singleMargin = isFullWidth ? containerMargins : GRID_MARGINS;

    return width - 2 * singleMargin;
  },
);

export const getMaxVideosCountInRow = createSelector(
  getWorkingAreaWidth,
  getThumbnailSpacing,
  getVideosInRowCount,
  (workingAreaWidth, spacing, inRowCount) => {
    // When stretched to full width the widget width is unknown in SSR (dimensions.width === '')
    // So we show number of thumbnails specified in the Settings
    if (!workingAreaWidth) {
      return inRowCount;
    }
    const itemWithSpacingWidth = WIDTH_CONSTRAINTS[0] + spacing;
    const itemsCanFitCount = Math.floor(
      workingAreaWidth / itemWithSpacingWidth,
    );
    return Math.min(Math.max(itemsCanFitCount, 1), inRowCount);
  },
);

const roundToTwoDigits = (value) => Math.round(value * 100) / 100;

export const getThumbnailWidth = createSelector(
  getWorkingAreaWidth,
  getThumbnailSpacing,
  getVideosInRowCount,
  getMaxVideosCountInRow,
  (width, spacing, inRowCount, maxInRowCount) => {
    const itemsInRowCount = Math.min(maxInRowCount, inRowCount);
    const spacingTotal = (itemsInRowCount - 1) * spacing;
    const availableWidth =
      maxInRowCount >= inRowCount || spacing ? width - spacingTotal : width;
    const widthCandidate = roundToTwoDigits(availableWidth / itemsInRowCount);
    return _.clamp(widthCandidate, ...WIDTH_CONSTRAINTS);
  },
);

const getForcedSpacing = createSelector(getThumbnailWidth, (width) => {
  /* eslint-disable curly */
  if (width > 699) return 80;
  if (width > 499) return 60;
  return 50;
});

export const getRowSpacing = createSelector(
  canShowVideoListItemContentBelow,
  getThumbnailSpacing,
  getForcedSpacing,
  (shouldForceMinSpacing, spacing, forcedSpacing) =>
    shouldForceMinSpacing
      ? _.clamp(spacing, forcedSpacing, MAX_SPACING)
      : spacing,
);
